export default function () {
  // Check for client side rendering and for decktop, tablet or mobile using event listener resize

  if (import.meta.client) {
    const type = ref<'desktop' | 'tablet' | 'mobile'>('desktop')

    const checkDevice = () => {
      if (window.innerWidth < 768) {
        type.value = 'mobile'
      } else if (window.innerWidth < 1024) {
        type.value = 'tablet'
      } else {
        type.value = 'desktop'
      }
    }

    checkDevice()

    window.addEventListener('resize', checkDevice)

    return type.value
  }

  return 'desktop'
}
